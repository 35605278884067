.version-container {
  margin-top: 50px;
  display: flex;
  justify-content: center;
}

.version-text {
  font-size: 12px;
  color: white;
  /* text-decoration: underline; */
}

.custom-sidebar-wrapper {
  /* background-color: #9d2233 !important; */
  background-image: none !important;
}

.tinted-image {
  /* filter: brightness(0) invert(1); */
}
.version-container {
  display: flex;
  align-items: center;
  padding-top: 4px;
  padding-bottom: 4px;
}

.version-text {
  font-size: 12px;
  font-weight: 500;
  color: #6c757d; /* Neutral gray color */
  letter-spacing: 0.5px;
}
