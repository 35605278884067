.login-title {
  font-size: 2rem;
  line-height: 3rem;
  color: #000;
}

.social {
  &-list {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 0.875rem;
    padding: 0;
    list-style: none;
  }

  &-item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    border: 2px solid #1a2e36;
    border-radius: 50%;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      border-color: #9D2233;

      .social-icon {
        color: #9D2233;
      }
    }
  }

  &-icon {
    font-size: 1rem;
    color: #1a2e36;
    transition: 0.3s;
  }
}

.custom-input {
  width: 100%;
  padding: 0.5rem 1rem;
  border: 1px solid #e4eaea;
  border-radius: 0.375rem;
  font-family: "Montserrat", sans-serif;
  // color: #ff5455;

  &::placeholder {
    color: #e4eaea;
    opacity: 1;
  }

  //   &:focus-visible {
  //     outline: 2px solid #ff5455;
  //   }
}

.reg-custom-wrapper {
  width: 100%;
  min-height: 100vh;
  background-color: #686868;
  background-image: url(../../assets/style/image/loginBackground.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 8px;
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #0000001e;
    top: 0;
    left: 0;
  }
  .custom-col-reg {
    width: 100%;
    max-width: 800px;
    background-color: #2e2e2e;
    transition: all ease 0.5s;
    position: relative;
    z-index: 1;
    cursor: pointer;

  }
}
.reg-custom-wrapper .special-label {
  display: none !important;
}

.react-tel-input .form-control {
  height: 42px !important; /* Adjust input height */
}

.react-tel-input {
  width: 100% !important; /* Ensure full width */
}

