.search-box {
  position: relative;

  input[type="text"] {
    font-size: 0.875rem;
    background: #f8f9fa;
    width: 300px;
    padding: 4px 10px;
    padding-right: 30px;
    line-height: 20px;

    &::placeholder {
      color: black; /* Change placeholder text color as desired */
    }
  }

  .search-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }


}


.special-label {
  display: contents !important;
}

.react-tel-input .form-control {
  margin-top: 8px ;
  height: 38px !important; /* Adjust input height */
}

.react-tel-input {
  width: 100% !important; /* Ensure full width */
}

