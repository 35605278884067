$bg-color: #686868;
$bg-transparent: #000000bd;
.auth-screen-wrapper {
  width: 100%;
  min-height: 100vh;
  background-color: $bg-color;
  background-image: url(../style/image/loginBackground.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: $bg-transparent;
    top: 0;
    left: 0;
  }
  .form-container {
    width: 100%;
    max-width: 800px;
    // background-color: #2e2e2e;
    // transition: all ease 0.5s;
    // position: relative;
    z-index: 1;

    input {
      box-shadow: none !important;
      outline: none !important;
      border: none !important;
      background-color: #fff !important;
      &::placeholder {
        color: #000 !important;
        opacity: 0.6;
      }
    }
  }
}
