.ddot-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}
.ddot-spinner {
  background: #9D2233;
  height: 10px;
  width: 80px;
  border-radius: 10px;
  position: relative;
  animation: loader 2s ease-in-out infinite;
}

.ddot-spinner::before,
.ddot-spinner::after {
  content: "";
  position: absolute;
  height: 10px;
  border-radius: 10px;
  left: 20px;
  animation: loader 2s ease-in-out infinite;
}

.ddot-spinner::before {
  background: #ffcccb;
  top: 20px;
  width: 50px;
}

.ddot-spinner::after {
  background: #FDF1F3;
  top: -20px;
  width: 60px;
}

@keyframes loader {
  0% {
    transform: translateX(50px) scale(1);
  }

  50% {
    transform: translateX(-30px) scale(1.1);
  }

  100% {
    transform: translateX(50px) scale(1);
  }
}
